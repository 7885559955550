import { Flex } from '@chakra-ui/react';
import React from 'react';
import InstagramEmbed from 'react-instagram-embed';
import { IInstagramEmbedProps } from './interfaces';

const Instagram: React.FC<IInstagramEmbedProps> = (props) => {
  return (
    <Flex
      justify="center"
      align="center"
      sx={{
        '.instagram-embed': { width: '100%' },
        iframe: { margin: '0 auto !important' },
      }}
    >
      <InstagramEmbed
        className="instagram-embed"
        url={props.node.url}
        clientAccessToken={process.env.NEXT_PUBLIC_INSTAGRAM_ACCESS_TOKEN ?? ''}
        containerTagName="div"
        maxWidth={700}
      />
    </Flex>
  );
};

export default Instagram;
