import React from 'react';
import { Flex } from '@chakra-ui/react';
import Image from 'next/image';
import { useAboutMe } from '../AboutMeContext';

const AboutMeImage: React.FC = () => {
  const { allHomepage } = useAboutMe();
  const image = allHomepage[0].aboutMeImage;
  return (
    <Flex
      justify={['center', null, 'flex-end']}
      align="center"
      pt={[4, null, 0]}
      w={['100%', null, 'auto']}
    >
      <Image
        src={image?.asset?.url ?? ''}
        width={572}
        height={572}
        placeholder="blur"
        alt={image?.alt ?? ''}
        blurDataURL={image?.asset?.metadata?.lqip ?? ''}
        style={{ maxWidth: '100%', height: 'auto' }}
      />
    </Flex>
  );
};

export default AboutMeImage;
