// eslint-disable-next-line no-shadow
export enum IImageLayout {
  PORTRAIT = 'portrait',
  LANDSCAPE = 'landscape',
  SQUARE = 'square',
}
interface IAspectRatioResponse {
  width: number;
  height: number;
}
export const getAspectRatio = (layout: IImageLayout): IAspectRatioResponse => {
  if (layout === IImageLayout.PORTRAIT) return { width: 547, height: 730 };
  if (layout === IImageLayout.SQUARE) return { width: 547, height: 547 };
  return {
    width: 730,
    height: 547,
  };
};
