import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { AboutMeTitleAndText } from './AboutMeTitleAndText';
import { AboutMeImage } from './AboutMeImage';
import FadeInWhenVisible from '@components/Shared/FadeInWhenVisible/FadeInWhenVisible';

const AboutMe: React.FC = () => {
  return (
    <Box as="section" id="about" bgColor="white">
      <FadeInWhenVisible>
        <Box w="100%">
          <Flex
            direction={['column', null, 'row']}
            justify="space-between"
            width="100%"
            margin="0 auto"
            pl={[0, null, '40px']}
          >
            <AboutMeTitleAndText />
            <AboutMeImage />
          </Flex>
        </Box>
      </FadeInWhenVisible>
    </Box>
  );
};

export default AboutMe;
