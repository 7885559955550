import React, { useState } from 'react';
import Image from 'next/legacy/image';
import { Fade, Skeleton } from '@chakra-ui/react';
import { IMyImageProps } from './interfaces';
import { getAspectRatio, IImageLayout } from './utils/helpers';
import { urlFor } from '@src/lib/sanity/sanity.client';
import { colorsGrey } from '@src/theme/chakraTheme/colors';
import CenteredFlex from '@components/Shared/CenteredFlex';

const MyImage: React.FC<IMyImageProps> = (props) => {
  const [loaded, setLoaded] = useState(false);
  return (
    <>
      <Fade in>
        <Skeleton isLoaded={loaded}>
          <Image
            src={urlFor(props.node.asset).auto('format').url() ?? ''}
            alt={props.node.alt}
            layout="responsive"
            {...getAspectRatio(props.node.layout as IImageLayout)}
            onLoad={() => setLoaded(true)}
          />
        </Skeleton>
      </Fade>
      {props.node.caption && (
        <CenteredFlex
          as="div"
          fontFamily="montserrat"
          fontWeight="light"
          mt={2}
          fontSize="14px"
          color={colorsGrey[300]}
        >
          {props.node.caption}
        </CenteredFlex>
      )}
    </>
  );
};

export default MyImage;
