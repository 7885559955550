import React from 'react';
import { Flex } from '@chakra-ui/react';
import { useAboutMe } from '../AboutMeContext';
import { NavigationButton } from '@components/Shared/NavigationButton';
import SectionHeader from '@components/Shared/SectionHeader';
import SanityText from '@components/Shared/SanityText';

const AboutMeTitleAndText: React.FC = () => {
  const { allHomepage } = useAboutMe();
  return (
    <Flex
      direction="column"
      mb={0}
      pr={10}
      maxW="700px"
      py={['10px', null, '82px']}
      align={['center', null, 'flex-start']}
      flex="1"
      ml={[10, null, 'max(calc(((100vw - 1108px) / 2) - 40px), 0px)']}
    >
      <SectionHeader normalText="About" boldText="Me" />
      <SanityText
        content={allHomepage[0].aboutMeContentRaw}
        mt={[2, null, 8]}
        mb={[8, null, '25px']}
        textAlign={['center', null, 'left']}
      />
      <NavigationButton href="/about" mb={[4, null, 0]}>
        Read More
      </NavigationButton>
    </Flex>
  );
};

export default AboutMeTitleAndText;
