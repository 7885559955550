import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { Text } from '@chakra-ui/react';
import { serializers } from '../Sanity/serializers';
import { ISanityTextProps } from './interfaces';
import { TextWrapper } from './SanityTextStyled';
import { colorsGrey } from '@src/theme/chakraTheme/colors';

const SanityText: React.FC<ISanityTextProps> = ({
  content,
  withoutBottomMargin,
  ...rest
}) => {
  return (
    <Text
      as="div"
      fontFamily="montserrat"
      fontWeight="light"
      color={colorsGrey[300]}
      fontSize="lg"
      lineHeight="1.7"
      {...rest}
    >
      <TextWrapper withoutBottomMargin={!!withoutBottomMargin}>
        <BlockContent blocks={content} serializers={serializers} />
      </TextWrapper>
    </Text>
  );
};

export default SanityText;
