import React from 'react';
import { IUrlProps } from './interfaces';

const Url: FCWithChildren<IUrlProps> = ({ children, mark }) => (
  <a href={mark.href} target="_blank" rel="noreferrer">
    {children}
  </a>
);

export default Url;
