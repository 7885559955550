import InstagramEmbed from './Instagram';
import MyImage from './MyImage';
import Url from './Url/Url';

export const serializers = {
  types: {
    myImage: MyImage,
    instagramPost: InstagramEmbed,
  },
  marks: {
    link: Url,
  },
};
